<template>
  <div class="lineList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>线路列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>商品ID:</span>
      <el-input
        style="width: 100px"
        v-model="list.travelId"
        placeholder="请输入商品ID"
      ></el-input>
      <span>商品名称:</span>
      <el-input v-model="list.travelName" placeholder="请输入商品名称"></el-input>
      <span>标签:</span>
      <el-input v-model="list.label" placeholder="请输入标签名称"></el-input>
      <span>状态:</span>
      <el-select clearable @clear="onClear(1)" v-model="list.status" placeholder="请选择">
        <el-option
          v-for="item in status"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div style="margin-top: 15px">
        <span>是否显示:</span>
        <el-select clearable v-model="list.isShow" placeholder="请选择">
          <el-option
            v-for="item in isShowOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span>万旅网显示:</span>
        <el-select clearable v-model="list.wlShow" placeholder="请选择">
          <el-option
            v-for="item in optionsShow"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span>所属分类:</span>

        <el-select @clear="onClear(2)" clearable v-model="cateValue" placeholder="请选择">
          <el-option
            v-for="item in cateListA"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
        <el-select
          clearable
          @clear="onClear(3)"
          v-show="cateValue"
          v-model="cateValue1"
          placeholder="请选择"
        >
          <el-option
            v-for="item in cateListB"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
        <el-select
          @clear="onClear(4)"
          clearable
          v-show="cateValue1"
          v-model="list.cateId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in cateListC"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="getLineList()">查找</el-button>
        <el-button
          type="primary"
          @click="navTo('/addLine/0')"
          v-if="$store.state.powerList.indexOf('travel:info:list:add') !== -1"
          >添加</el-button
        >
      </div>
    </div>
    <el-table
      @selection-change="onSelectionChange"
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column align="center" type="selection"></el-table-column>
      <el-table-column align="center" prop="dept" label="排序">
        <template slot-scope="{ row }">
          <el-input
            type="number"
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button v-else class="button-new-tag" @click="onChangeSort(row)">{{
            row.dept
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="travelId" label="商品ID"></el-table-column>
      <el-table-column
        align="center"
        prop="travelName"
        label="主标题"
        width="300"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="cateName"
        label="所属分类"
        width="150"
      ></el-table-column>
      <el-table-column align="center" prop="label" label="标签"></el-table-column>
      <el-table-column align="center" prop="sellWay" label="销售方式"></el-table-column>
      <el-table-column align="center" prop="score" label="评分"></el-table-column>
      <el-table-column
        align="center"
        prop="unsubscribe"
        label="是否退订"
      ></el-table-column>
      <el-table-column align="center" prop="strChange" label="是否改期"></el-table-column>
      <el-table-column align="center" prop="strStatus" label="状态"></el-table-column>
      <el-table-column align="center" prop="isShow" label="是否显示"> </el-table-column>
      <el-table-column align="center" prop="wlShow" label="万旅网显示"> </el-table-column>
      <el-table-column align="center" label="操作" width="450">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            v-show="row.status == 2"
            @click="onChangeStatus(row, 1)"
            v-if="$store.state.powerList.indexOf('travel:info:list:update') !== -1"
            >上架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            v-show="row.status == 1 || row.status == 3"
            @click="onChangeStatus(row, 2)"
            v-if="$store.state.powerList.indexOf('travel:info:list:update') !== -1"
            >下架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="navTo(`/addLine/${row.travelId}`)"
            v-if="$store.state.powerList.indexOf('travel:info:list:edit') !== -1"
            >编辑</el-button
          >

          <!-- v-if="
              $store.state.powerList.indexOf('travel:specs:list:select') !== -1
            " -->
          <el-button
            size="mini"
            type="primary"
            @click="navTo(`/travelSpecsList/${row.travelId}`)"
            >套餐详情</el-button
          >
          <!-- <el-button
            type="primary"
            size="mini"
            style="width: 75px"
            @click="toTrip(row.travelId)"
            >行程管理</el-button
          > -->
          <el-dropdown style="margin-left: 10px">
            <el-button type="primary" size="mini">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="primary"
                  @click="navTo(`/lineLog/${row.travelId}`)"
                  v-if="$store.state.powerList.indexOf('travel:info:list:log') !== -1"
                  >操作日志</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="primary"
                  style="width: 75px"
                  @click="onChangeStatus(row, 0)"
                  v-if="$store.state.powerList.indexOf('travel:info:list:update') !== -1"
                  >删除</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="primary"
                  style="width: 75px"
                  @click="onShowAddress(row.travelId)"
                  >查看页面路径</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="primary"
                  size="mini"
                  style="width: 75px"
                  v-if="$store.state.powerList.indexOf('travel:info:list:add') !== -1"
                  @click="addByCodyLine(row.travelId)"
                  >一键复制</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="primary"
                  size="mini"
                  style="width: 75px"
                  @click="creatCode(row.travelId)"
                  >生成小程序码</el-button
                >
              </el-dropdown-item>

              <!-- <el-dropdown-item>蚵仔煎</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="btn">
      <el-button
        type="primary"
        @click="onChangeStatus1(1)"
        v-if="$store.state.powerList.indexOf('travel:info:list:update') !== -1"
        >批量上架</el-button
      >
      <el-button
        type="primary"
        @click="onChangeStatus1(2)"
        v-if="$store.state.powerList.indexOf('travel:info:list:update') !== -1"
        >批量下架</el-button
      >
      <el-button
        type="primary"
        @click="onChangeStatus1(0)"
        v-if="$store.state.powerList.indexOf('travel:info:list:update') !== -1"
        >批量删除</el-button
      >
      <el-button type="primary" @click="onChangeStatus1(3)">批量推荐</el-button>
      <el-button type="primary" @click="onChangeStatus1(1)">批量取消推荐</el-button>
      <el-button type="primary" @click="onAddHot(1)">批量热门</el-button>
      <el-button type="primary" @click="onAddHot(0)">批量取消热门</el-button>
      <el-button
        type="primary"
        @click="onChangeAllStatusShow(1)"
        v-if="$store.state.powerList.indexOf('travel:info:list:wlShow') !== -1"
        >万旅网批量显示</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAllStatusShow(0)"
        v-if="$store.state.powerList.indexOf('travel:info:list:wlShow') !== -1"
        >万旅网批量隐藏</el-button
      >
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog title="查看线路页面详情地址" :visible.sync="showAddress" width="35%">
      <div id="address">
        前台线路详情地址:<el-input v-model="address" readonly id="wrapper"></el-input>
        <el-button type="primary" icon="el-icon-document-copy" @click="copyinviteUrl"
          >一键复制</el-button
        >
      </div>
      <div slot="footer">
        <el-button @click="showAddress = false">取 消</el-button>
        <el-button type="primary" @click="showAddress = false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input
          style="width: 300px"
          v-model.trim="QRcodeSize"
          placeholder="请输入生成的二维码大小"
        ></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color: red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  lineList,
  changeStatus,
  changeSort,
  addByCody,
  addHot,
} from "../../api/lineList";
import { createEr } from "../../api/erCode";
import { selectAllByFatherId,wanlvLineShow } from "../../api/travel";
export default {
  name: "lineList",
  data() {
    return {
      QRcodeSize: "",
      codeVisible: false,
      travelId: "",
      imgUrl: "",
      showAddress: false,
      address: "",
      ids: "",
      tableData: [],
      pagination: {},
      cateValue: null,
      cateValue1: null,
      cateListA: [],
      cateListB: [],
      cateListC: [],
      isShowOptions: [
        {
          value: -2,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      status: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "上架",
        },
        {
          value: 2,
          label: "下架",
        },
        {
          value: 3,
          label: "推荐",
        },
      ],
      pagination: {},
      list: {
        travelId: "0",
        currentPage: 1,
        pageSize: 5,
        travelName: "",
        label: "",
        status: null,
        cateId: null,
        isShow: -2,
        wlShow:-1
      },
      optionsShow: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
    };
  },
  watch: {
    cateValue: async function () {
      const { data } = await selectAllByFatherId({
        fatherId: this.cateValue,
      });
      this.$store.commit("setLinecateValue", this.cateValue);
      this.cateListB = data.data;
    },
    cateValue1: async function () {
      const { data } = await selectAllByFatherId({
        fatherId: this.cateValue1,
      });
      this.$store.commit("setLinecateValue1", this.cateValue1);
      this.cateListC = data.data;
    },
    "list.cateId": function () {
      this.$store.commit("setLinecateId", this.list.cateId);
    },
  },
  created() {
    this.cateValue = this.$store.state.linecateValue;
    this.cateValue1 = this.$store.state.linecateValue1;
    this.list.cateId = this.$store.state.cateId;

    this.navTo();
    this.getLineList();
    this.getTravelCate();
  },
  methods: {
    onChangeAllStatusShow(status){
      if(!this.ids){
        return this.$message({
          type: "warning",
          message: "请选择要修改的内容!",
        });
      }
      // 是否修改
      this.$confirm("是否要批量修改选中线路的状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          console.log(this.ids)
          const { data } = await wanlvLineShow({
            travelId: this.ids,
            wlShow:status,
          });
          if (data.code === 0) {
            this.getLineList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          
        });

    },
    // 跳转行程管理
    toTrip(id) {
      this.$router.push("/trip?travelId=" + id);
    },
    creatCode(id) {
      this.travelId = id;
      this.codeVisible = true;
      this.imgUrl = "";
    },
    async generateQRcode() {
      this.imgUrl = "";
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await createEr({
          travelId: this.travelId,
          widthSize: this.QRcodeSize,
        });
        this.imgUrl = data.msg;
        console.log(this.imgUrl);
      }
    },
    onShowAddress(id) {
      this.address = "freeTravel/freeTravelDetail/freeTravelDetail?travelId=" + id;
      this.showAddress = true;
    },
    copyinviteUrl() {
      this.$nextTick(() => {
        var range = document.createRange();
        range.selectNode(document.getElementById("wrapper"));
        var selection = window.getSelection();
        if (selection.rangeCount > 0) selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
      });
    },
    navTo(url) {
      if (url) {
        sessionStorage.setItem("WANLV_TRAVEL_LINELIST_PAGE", this.list.currentPage);
        this.$router.push(url);
      } else {
        this.list.currentPage =
          Number(sessionStorage.getItem("WANLV_TRAVEL_LINELIST_PAGE")) || 1;
        sessionStorage.setItem("WANLV_TRAVEL_LINELIST_PAGE", 1);
      }
    },
    addByCodyLine(travelId) {
      this.$confirm("确定一键复制这条收据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await addByCody({
            travelId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getLineList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    async getLineList() {
      const { data } = await lineList(this.list);
      console.log(data);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        travelId: row.travelId,
        dept: Number(row.dept),
      });
      if (data.code === 0) {
        row.inputVisible = false;
        this.getLineList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async getTravelCate() {
      const { data } = await selectAllByFatherId({
        fatherId: 0,
      });
      console.log(data);
      this.cateListA = data.data;
    },
    onChangeStatus(row, status) {
      this.$confirm("是否改变这条数据的变化", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            ids: row.travelId + "",
            status,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: "成功!",
            });
            this.getLineList();
          } else {
            this.$message({
              type: "error",
              message: "失败,请稍重试!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSelectionChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.travelId);
      });
      this.ids = arr.join(",");
    },
    onAddHot(status) {
      console.log(status, "status");
      this.$confirm("是否批量改变这些数据的变化", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        if (this.ids == "") {
          this.$message({
            type: "error",
            message: "请选择要改变的数据!",
          });
          return;
        }
        const { data } = await addHot({
          ids: this.ids,
          hot: status,
        });
        if (data.code == 0) {
          this.$message({
            type: "success",
            message: data.msg,
          });
        } else {
          this.$message({
            type: "error",
            message: "失败,请稍重试!",
          });
        }
      });
    },
    onChangeStatus1(status) {
      this.$confirm("是否批量改变这些数据的变化", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids == "") {
            this.$message({
              type: "error",
              message: "请选择要改变的数据!",
            });
            return;
          }
          const { data } = await changeStatus({
            ids: this.ids,
            status,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: "成功!",
            });
            this.getLineList();
          } else {
            this.$message({
              type: "error",
              message: "失败,请稍重试!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onClear(id) {
      if (id == 1) {
        this.list.status = null;
      } else if (id == 2) {
        this.list.cateId = null;
        this.cateValue = null;
        this.cateValue1 = null;
      } else if (id == 3) {
        this.cateValue1 = null;
        this.cateValue1 = null;
      } else if (id == 4) {
        this.list.cateId = null;
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getLineList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getLineList();
    },
  },
};
</script>
<style lang="less" scoped>
.lineList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin: 0 10px 0 40px;
    }
    .el-input {
      width: 200px;
    }
    .el-button {
      margin: 0 20px;
    }
    .el-select {
      width: 150px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .btn {
    float: left;
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;

    float: right;
  }
  #address {
    display: flex;
    align-items: center;

    .el-input {
      width: 350px;
    }
  }
}
</style>
